@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
}

*  {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

sup {
  font-size: 0.8rem;
}

.linkHover:hover {
  background: rgb(240, 241, 242) !important;
  cursor: pointer;
}

/* Class user for turning child component visible/invisible when hovering on parent element */
.parentInvisibleOnHover:hover .invisibleOnHover {
  display: none;  
}
.parentInvisibleOnHover:hover .visibleOnHover {
  display: inline !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #E2E5ED;
}

/* unwanted */
/* .MuiPopover-root {
  position: absolute !important;
  display: none;
} */

/* bug fix: line through password input label */
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #FFFFFF;
}

/* Eliminate input element spin/increment buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}
/* survey js custom styling by class overrides */

.sv_main {
  font-family: Inter, sans-serif !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: 0.4em !important;
  min-height: 0.6em !important;
}

/* question container box */
.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-weight: 600 !important;
  font-size: 15px !important;
  margin: 0.5em 0 !important;
  letter-spacing: 0.2px !important;
  color: #1A2259 !important;
}

/* question font color when disabled */
.sv_main .sv_container .sv_body .sv_p_root .sv_q--disabled .sv_q_title {
  color: #848484 !important;
}

/* gap between answer inputs and description   */
.sv_qstn fieldset {
  margin-top: 24px !important;
}

/* gap between input box and description */
.sv_q_text_root {
  margin-top: 10px !important;
}


/* eliminate the background color of alternate question containers  */
.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: white !important;
}


.sv_main .sv_container {
  /* color: #1A2259; */
}

/* Question title */
.sv_main .sv-title-actions__title {
  font-weight: 600 !important ;
}

/* outermost container  */
.sv_main .sv_body {
  border-top: 2px solid #706CEC !important;
}

.sv_q_radiogroup_control_item {
  background-color: blue !important;
  color: blue !important;
  fill: blue !important;
}

.svd_container.sv_default_css label {
  display: inline-block !important;
  max-width: 100% !important;
  margin-bottom: 5px !important;
  font-weight: 400 !important;
  vertical-align: top !important;
}

/* for survey js info button override styles*/
.btn-info {
  background-color: rgba(112, 108, 236, 0.75) !important;
  justify-content: center !important;
  align-items: center !important;
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  border: 1px solid white !important;
  overflow-x: auto !important;
  margin: 0px !important;
  padding: 0px !important;
  color: white !important;
  font-size: 11px !important;
  cursor: auto !important;
  line-height: 0px !important;
}

.btn-info:active {
  opacity: 1 !important;
}

/* for info-button when no description found */
.invisible {
  display: none !important;
}

/* container for questions and info-button   */
.sv_main .sv-title-actions {
  flex-wrap: wrap;
}

/* for survey js info button positioning next to question */
.sv_main .sv-action-bar {
  margin-left: 0px !important;
  align-self: start;
  margin-top: 2px;
}
.sv_main .sv-title-actions__title {
  min-width: 0px !important;
}

/* for question descriptions in survey js */
.sv_q_description {
  /* display: none; */
  font-size: 12px !important;
}

/* buttons */
.sv_main .sv_container .sv_body .sv_nav .sv_start_btn, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn, .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  background: #706CEC;
  border-radius: 6px;
  padding: 8px 36px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: white;
  float: none;
  margin: 20px 0px 0px 14px !important;
}

/* Clear button for all uploaded files (has typo) */
.sv_main .sv_qstn .sv_q_file .sv_q_file_remove_button {
  display: none;
}

/* clear button (text) for individual uploaded files */
.sv_q_file_remove:hover {
  color: red !important;
}

.sv_q_file_remove {
  margin-top: 5px;
}

/* box around uploaded files */
.sv_q_file_preview {
  padding: 10px !important;
}

/* remove float so questions iand inputs on seperate lines */
.sv_qstn .title-left {
  float: none !important;
}

/* for the file link when display mode on */
.sv_q--disabled a:link {
  pointer-events: none !important;
  color: #848484 !important;
}

/* -------------------- */
/* Custom input radio button type ** delete if easier way around using survey js */

/* hide native radio button */
label > input[type="radio"] {
  display: none;
}

/* make own radio button */
label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: center !important;
  width: 0.85rem;
  height: 0.82rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

/* create own radio button change when checked */
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(blue 0%, blue 40%, transparent 50%, transparent);
  border-color: blue;
}
label > input[type="radio"]:checked + * {
  display: flex;
  align-items: center;
  color: blue;
}

.sv_q_radiogroup_label, .circle  {
  display: flex !important;
  align-items: center;
}

main {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

sup {
  font-size: 0.8rem;
}

.linkHover:hover {
  background: rgb(240, 241, 242) !important;
  cursor: pointer;
}

/* Class user for turning child component visible/invisible when hovering on parent element */
.parentInvisibleOnHover:hover .invisibleOnHover {
  display: none;  
}
.parentInvisibleOnHover:hover .visibleOnHover {
  display: inline !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #E2E5ED;
}

/* unwanted */
/* .MuiPopover-root {
  position: absolute !important;
  display: none;
} */

/* bug fix: line through password input label */
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #FFFFFF;
}

/* Eliminate input element spin/increment buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}